import React from 'react'

const Prueba = () => {
    return (
        <div>
            <h1>Esto es una prueba</h1>
        </div>
    )
}

export default Prueba
